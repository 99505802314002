import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { AdminGuard } from './shared/adminGuard/admin.guard';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: './layout/layout.module#LayoutModule',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard]
    },
    {
        path: 'admin',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule',
        canActivate: [AdminGuard],
        canActivateChild: [AdminGuard]
    },
    {
        path: 'login',
        loadChildren: './login/login.module#LoginModule',
    },
    {
        path: 'forgot-password',
        loadChildren: './forget-password/forget-password.module#ForgetPasswordModule'
    },
    {
        path: 'admin-login',
        loadChildren: './admin-login/admin-login.module#AdminLoginModule'
    },
    {
        path: 'update-password/:token',
        loadChildren: './update-password/update-password.module#UpdatePasswordModule'
    },
    {
        path: 'privacyPolicy',
        loadChildren: './privacy-policy/privacy-policy.module#PrivacyPolicyModule'
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, AdminGuard]
})
export class AppRoutingModule {}
