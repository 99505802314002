import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {formDetails} from "../model/course";
import {map} from "rxjs/operators";
import {Lesson} from "../model/lesson";


@Injectable()
export class CoursesService {

    constructor(private http:HttpClient) {

    }

    findCourseById(courseId: string): Observable<formDetails> {
        return this.http.get<formDetails>(`/api/courses/${courseId}`);
    }

    findAllCourses(): Observable<formDetails[]> {
        return this.http.get('/api/courses')
            .pipe(
                map(res => res['payload'])
            );
    }

    findAllCourseLessons(courseId:string): Observable<Lesson[]> {
        return this.http.get('/api/lessons', {
            params: new HttpParams()
                .set('courseId', courseId.toString())
                .set('pageNumber', "0")
                .set('pageSize', "1000")
        }).pipe(
            map(res =>  res["payload"])
        );
    }

    findFroms(
        userID:string, filter = '', sortOrder = 'asc',
        pageNumber = 0, pageSize = 3):  Observable<formDetails[]> {

        return this.http.get('http://192.168.0.57:5051/api/getMyForm/', {
            params: new HttpParams()
                .set('userId', userID)
                .set('filter', filter)
                .set('sortOrder', sortOrder)
                .set('pageNumber', pageNumber.toString())
                .set('pageSize', pageSize.toString())
        }).pipe(
            map(res =>  res["data"])
        );
    }

}