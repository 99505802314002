import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpResponse,HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor{
	token = '';
	constructor(private router:Router){}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(localStorage.getItem('token')!=""){
			this.token = localStorage.getItem('token');
		}
		if(this.token){
			req = req.clone({ 
				headers: req.headers.set("x-auth-token", this.token)
			});
			//send the newly created request
		}
		return next.handle(req)
            .do((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
            }, (err: any) => {
				if (err instanceof HttpErrorResponse) {
					if (err.status === 403 ) {
						this.router.navigate(['login']);
					}
				}
            });
	}
}