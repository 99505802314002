import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AdminGuard implements CanActivate,CanActivateChild {

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(localStorage.getItem('isAdminLoggedin') == 'true') {
        return true;
      }else if(localStorage.getItem('isAdminLoggedin') == 'false' || localStorage.getItem('isAdminLoggedin') == null || localStorage.getItem('isAdminLoggedin') == undefined ){
        this.router.navigate(['/admin-login']);
        return false;
      }
  }

  canActivateChild(){
    if(localStorage.getItem('isAdminLoggedin') == 'true') {
      return true;
    }else if(localStorage.getItem('isAdminLoggedin') == 'false' || localStorage.getItem('isAdminLoggedin') == null || localStorage.getItem('isAdminLoggedin') == undefined ){
      this.router.navigate(['/admin-login']);
      return false;
    }
  }
}